import { breakpoints, colors, v2Colors } from '@web-for-marketing/react-ui';

export const createCustomFormStyles = {
    linkText: (backgroundEnabled: boolean) => ({
        '& a': {
            color: `${backgroundEnabled ? v2Colors.text.inversePrimary : v2Colors.text.tertiary} !important`,
        },
    }),
    warningText: {
        textAlign: 'left' as const,
        color: colors.prompts.alert,
        marginTop: '1rem',
    },
    mainContainer: (padding: { paddingTop: number; paddingBottom: number }) => ({
        paddingTop: `${padding.paddingTop}rem !important`,
        paddingBottom: `${padding.paddingBottom}rem !important`,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '1rem 1.6rem !important',
        },
    }),
};
